import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import Navmenu from './NavMenu';
import MenuMobil from './MobilMenu/MenuMobil';



const HeaderNew1 = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <>
            <header className="lg:absolute relative top-3 right-0 w-full z-[11]">
                <div className="w-[90%] flex-col h-auto hidden lg:flex">
                    <div className="w-[80%] h-auto relative">
                        {rpdata?.simpleWidgets?.[3]?.activo ? null : (
                            <div className="ml-7 w-4/5  pr-36 rounded-b-3xl py-[2px] bg-[#2e2e2e] flex space-x-6 items-center relative z-[50] inverted-border-radius ">
                                <div className='w-[200px] h-[200px] bg-contain bg-center bg-no-repeat ml-28' style={{backgroundImage: `url("${rpdata?.dbPrincipal?.logo}")`}} >
                                    
                                </div>
                                <div className='w-1/2'>
                                    <Navmenu colorText="text-black " />
                                </div>
                            </div>

                        )}
                    </div>
                </div>

                <div className="flex lg:hidden justify-center w-full">
                    <div className="w-[75%] h-auto relative">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            loading='lazy'
                            className='w-[90%] mx-auto md:ml-16'
                        />
                    </div>
                    <div className="w-[35%] flex items-center justify-center">
                        <MenuMobil />
                    </div>
                </div>
            </header >
        </>
    )
}

export default HeaderNew1